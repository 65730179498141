@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Oswald, Roboto;
    /* background-image: url('../public/assets/img/backround.png'); */
  }

  body {
    line-height: 1.5;
    position: relative;
  }

  :root {
    --header-outer-height: 80px;
    --header-inner-height: 70px;
    --header-height-difference: calc(var(--header-outer-height) - var(--header-inner-height));
    --header-bg: #fff;
  }

  .responsive-wrapper {
    width: 90%;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

  /* Sticky header */
  .header-outer {
    /* Make it stick */
    height: var(--header-outer-height);
    position: sticky;
    top: calc(var(--header-height-difference) * -1);
    /* Multiply by -1 to get a negative value */
    display: flex;
    align-items: center;

    /* Other */
    background-color: var(--header-bg);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .navigation_border {
    background: linear-gradient(180deg, rgba(26, 28, 30, 0) 0%, #1A1C1E 100%);
  }

  .science {
    width: 1000px;
  }

  .science-2 {
    width: 1500px;
  }

  #root .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }

  /* Snow Theme */
  #root .ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  #root .ql-editor {
    min-height: 18em;
  }

  .bg-black-portrait {
    background: linear-gradient(89.54deg, #000000 0.44%, rgba(0, 0, 0, 0) 99.66%);
  }

  .science_header {
    background: linear-gradient(180deg, #C04106 31.38%, #EE763E 116.08%);
  }

  .video {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.8;
    transform: rotate(-180deg);
  }

  iframe {
    width: 100%;
    height: 600px;
  }

  .search-box {
    transition: width 0.6s, border-radius 0.6s, background 0.6s, box-shadow 0.6s;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    background: #EE763E;
  }

  .search-box+label .search-icon {
    color: black;
  }

  .search-box:hover {
    color: white;
    background: rgb(200, 200, 200);
  }

  .search-box:hover+label .search-icon {
    color: white;
  }

  .search-box:focus {
    transition: width 0.6s cubic-bezier(0, 1.22, 0.66, 1.39), border-radius 0.6s, background 0.6s;
    border: none;
    outline: none;
    box-shadow: none;
    padding-left: 15px;
    cursor: text;
    width: 300px;
    border-radius: auto;
    background: #EE763E;
    color: white;
  }

  .search-box:focus+label .search-icon {
    color: black;
  }

  .search-box:not(:focus) {
    text-indent: -5000px;
  }

  #search-submit {
    position: relative;
    left: -5000px;
  }

  .search-icon {
    position: relative;
    left: -30px;
    color: white;
    cursor: pointer;
  }

  .img-container {
    display: flex;
    overflow: hidden;
    width: 100%;
  }

  .text-1-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
  }

  .text-2-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }

  .text-3-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.5;
  }

  .text-4-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 4;
  }


}